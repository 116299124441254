@import './scss/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Lato', sans-serif ;
  margin: 0;
  padding: 0;
}

// ::-webkit-scrollbar {
//   width: 5px;
// }

// ::-webkit-scrollbar-corner { 
//   background: rgba(0,0,0,0.5);
// }

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 2px #e4e4e4e4; 
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   -webkit-box-shadow: inset 0 0 5px #e4e4e4e4; 
// }

// ::-webkit-scrollbar:horizontal {
//   height: 5px;
//   cursor: pointer;
//   -webkit-box-shadow: inset 0 0 2px #e4e4e4e4; 
//   border-radius: 10px;
// }