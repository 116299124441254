.precodecss {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  height: 320px;
  padding: 0;
  color: "black";
  text-align: justify;
}
