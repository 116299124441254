.headerContainer {
  background-color: $white-color !important;
  color: $text-black-color !important;
  box-shadow: none !important;
  .headerContent {
    display: flex;
    justify-content: flex-end; // Align content to the right
    align-items: center; // Center items vertically

    .headerButton {
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 0.1rem;
        font-size: 16px;
        color: $white-color; // Change to your desired color
    }
}
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #666666;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

// -------------- new nav bar 2/11/2021------------

.menuItems {
  position: relative;
}
.menuItems::after {
  // position: absolute;
  content: "";
  //background-color: white;
  // height: 4px;
  width: 0;
  // bottom: -15px;
  // transition: 0.3s;
  // left: 4%;
  // right: 4%;
}
.menuItems:hover::after {
  width: 100%;
  bottom: -15px;
  @media screen and (max-width: 960px) {
    width: 0%;
  }
}

.searchBoxDiv {
  @media screen and (max-width: 964px) {
    display: none;
  }
}


.menuItems {
  position: relative;
//  color: #18479F; /* Default color for inactive items */
  //padding: 0 15px; /* Space around items */
  transition: color 0.3s ease; /* Smooth color transition */
  text-decoration: none; /* Removes underline from links */
  padding: 0.5rem 1rem; /* Padding for better click area */
  transition: transform 0.3s ease; /* Smooth transition for size change */
  font-size: 16px;
  text-align: center;
    // padding: 3%;
    width: 100%;
    display: table;
    background-color: white;
  color: #18479F;

}
.menuItems:hover
{
  background-color: white;
  color: #18479F;
}
.menuItems1 {
  position: relative;
  padding:5px;
  background-color: #18479F;
  color: white;
  // border: 2px solid #18479F;
  //background-color: #18479F; /* Background color for active item */
 // color: white; /* Text color for active item */
 // padding: 0 15px; /* Space around active item */
 transition: color 0.3s ease; /* Smooth color transition */
 text-decoration: none; /* Removes underline from links */
 padding: 0.5rem 1rem; /* Padding for better click area */
 transition: transform 0.3s ease; /* Smooth transition for size change */
 font-size: 16px;
 text-align: center;
   width: 100%;
   display: table;/* Optional: adds rounded corners */
}

.menuItems1:hover
{
  background-color: #18479F;
  color: white;
}
// .menuItems1 {
//   position: relative;
//   color:red;
// }
// .menuItems1::after {
//   position: absolute;
//   content: "";
//   background-color: white;
//   font-size: 22px;
//   height: 4px;
//   width: 100%;
//   bottom: -15px;
//   transition: 0.3s;
//   left: 4%;
//   right: 4%;
//   @media screen and (max-width: 960px) {
//     width: 0%;
//   }
// }
// .navBar{
//     background-color: #048fd3;
//     height: 90px;
//     display: flex;
//     // flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     font-size: 18px;

// }

// .navBar {
//   //background-color: #003399;
//   // background-color: $theme-blue;
//   background-image: linear-gradient(to right, #18479F, #b41010); 
//   display: flex;
//   // justify-content: space-around;
//   // align-items: center;
//   flex-direction: column;
//   // font-size: 20px;
//   padding-left: 3%;
//   padding-top: 5px;
// }

.navBar {
  // background-image: linear-gradient(to right, #18479F, #b41010); 
  display: flex;
  justify-content: space-between; // Space between children
  align-items: center; // Align items vertically
 // padding: 5px 3%; // Padding for navBar
  height: 60px; // Set a height for the navbar
  margin-left: 50px;
}


.navLinksSearchContainer {
  display: flex;
  margin-left: 20px;
}

.navBarTitle {
  color: white;
  justify-self: start;
  margin-left: 25px;
}

.navMenu {
  // display: grid;
  // grid-template-columns: repeat(5,auto);
  // grid-gap: 10px;
  // list-style: none;
  // text-align: center;
  // width: 100%;
  // justify-content: end;
  // margin-right: 20px;
  // padding-top: 4px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  // margin-left: 12px;
  // padding-left: 2%;
}
.nav-links {
  color: #18479F; /* Text color */
  text-decoration: none; /* Removes underline from links */
  padding: 0.5rem 1rem; /* Padding for better click area */
  transition: transform 0.3s ease; /* Smooth transition for size change */
  font-size: 18px;
}


.menu-icon {
  display: none;
  
}

a:hover {
  //  color: white; 
   text-decoration: none; 
}

@media screen and (max-width: 960px) {
  .navBar {
    position: relative;
  }
  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .navMenu.active {
    //    background-color: #048fd3;
    background-color: $theme-blue;
    left: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 3%;
    width: 100%;
    display: table;
    font-size: 18px;
  }
  .nav-links:hover {
    background-color: #eceeef;
    border-radius: 0;
    left: -100%;
    text-decoration: none;
    transform: scale(1.1);
  }
  .navBarTitle {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }
}

/////////////////////// search comp ///////////////////////////////////

// .searchInputs {
//   margin-top: 105px;
//   display: flex;
// }

// .search input {
//   background-color: white;
//   border: 0;
//   border-radius: 2px;
//   border-top-right-radius: 0px;
//   border-bottom-right-radius: 0px;
//   font-size: 18px;
//   padding: 15px;
//   height: 30px;
//   width: 300px;
// }

// .searchIcon {
//   height: 60px;
//   width: 50px;
//   background-color: white;
//   display: grid;
//   place-items: center;
// }

// input:focus {
//   outline: none;
// }
// .searchIcon svg {
//   font-size: 35px;
// }

// .dataResult {
//   margin-top: 5px;
//   width: 300px;
//   height: 200px;
//   background-color: white;
//   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//   overflow: hidden;
//   overflow-y: auto;
// }

// .dataResult::-webkit-scrollbar {
//   display: none;
// }

// .dataResult .dataItem {
//   width: 100%;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   color: black;
// }

// .dataItem p {
//   margin-left: 10px;
// }
// a {
//   text-decoration: none;
// }

// a:hover {
//   background-color: lightgrey;
// }

// #clearBtn {
//   cursor: pointer;
// }

.searchBox {
  width: 220px;
  height: 10px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  margin-top: -2%;
}

.searchBox > .searchIcon {
}

.searchTextfield {
  flex: 1;
  height: 00px;
  border: none;
  outline: none;
  // padding-left: 10px;
  margin-left: 5px;
}
